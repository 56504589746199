import React from 'react';
import { TypographyVariant } from '@mui/material';
import { Box, Typography, TypographyPropsVariantOverrides } from '@mui/material';
import { OverridableStringUnion } from '@mui/types';

interface EmptyStateProps {
    button?: React.ReactNode;
    className?: string;
    description?: string;
    image: React.ReactNode;
    padding?: number;
    size?: 'small' | 'medium' | 'large';
    title?: string;
    type?: 'page' | 'card';
}

export const EmptyState: React.FC<React.PropsWithChildren<EmptyStateProps>> = ({ button, className, description, image, size = 'medium', padding = 2, title, type = 'page' }) => {
    let imageWidth;
    let imageHeight;
    let variant: OverridableStringUnion<TypographyVariant | 'inherit', TypographyPropsVariantOverrides>;

    switch (size) {
        case 'small':
            imageWidth = 40;
            imageHeight = 40;
            variant = 'h6';
            break;

        case 'large':
            imageWidth = 100;
            imageHeight = 100;
            variant = 'h4';
            break;

        default:
            imageWidth = 60;
            imageHeight = 60;
            variant = 'h5';
            break;
    }

    if (type === 'page') {
        return (
            <Box className={className} style={{ transform: 'translate(-50%, -50%)' }} position="absolute" top="50%" left="50%" textAlign="center">
                {image && (
                    <Box mb={title || description ? 2 : 0} width={`${imageWidth}%`} height={`${imageHeight}%`}>
                        {image}
                    </Box>
                )}

                {title && (
                    <Box mb={!description && button ? 2 : 0}>
                        <Typography variant={variant}>{title}</Typography>
                    </Box>
                )}

                {description && (
                    <Box mb={button ? 2 : 0}>
                        <Typography variant="body1">{description}</Typography>
                    </Box>
                )}

                {button ? button : null}
            </Box>
        );
    }

    if (type === 'card') {
        return (
            <Box className={className} padding={padding} textAlign="center">
                {image && (
                    <Box mb={title || description ? 2 : 0} width={`${imageWidth}%`} height={`${imageHeight}%`}>
                        {image}
                    </Box>
                )}

                {title && (
                    <Box mb={!description && button ? 2 : 0}>
                        <Typography variant={variant}>{title}</Typography>
                    </Box>
                )}

                {description && (
                    <Box mb={button ? 2 : 0}>
                        <Typography variant="body1">{description}</Typography>
                    </Box>
                )}

                {button ? button : null}
            </Box>
        );
    }

    return null;
};

export default EmptyState;

import { Home as HomeIcon } from '@mui/icons-material';
import { Box, Fab } from '@mui/material';
import { NextPage } from 'next';
import Head from 'next/head';
import Image from 'next/image';
import Link from 'next/link';
import EmptyState from '../components/EmptyState/EmptyState';

const NotFoundPage: NextPage = () => (
    <>
        <Head>
            <title>Page not found</title>
        </Head>
        <EmptyState
            image={<Image unoptimized={true} src="/assets/illustrations/not-found.svg" alt="Page not found illustration" width={500} height={500} />}
            title={'404 Page Not Found'}
            description={'The page you’re trying to access doesn’t exist'}
            button={
                <Link href="/" passHref={true} legacyBehavior>
                    <Fab variant="extended" color="primary">
                        <Box mr={1}>
                            <HomeIcon />
                        </Box>
                        Home
                    </Fab>
                </Link>
            }
        />
    </>
);

export const getStaticProps = async () => ({ props: {} });
export default NotFoundPage;
